import {
  AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD,
  AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD,
  AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD,
  DEFAULT_TERM_FOR_FIRST_RANGE,
  DEFAULT_TERM_FOR_FOURTH_RANGE,
  DEFAULT_TERM_FOR_SECOND_RANGE,
  DEFAULT_TERM_FOR_THIRD_RANGE,
  TERM_SLIDER_MAX_FOR_FIRST_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_FOURTH_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_THIRD_RANGE,
} from 'entities/FullDeal/config';

import type { RealTermBySliderValue, TermSliderRealData } from '../../types';

export const convertRealTermToSliderTerm = (amount: number, realTerm: number) => {
  if (amount < AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD) {
    return findSliderTermForRange(TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE, realTerm, DEFAULT_TERM_FOR_FIRST_RANGE);
  }

  if (AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD <= amount && amount < AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD) {
    if (realTerm <= TERM_SLIDER_MAX_FOR_FIRST_RANGE) {
      return findSliderTermForRange(TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE, realTerm, DEFAULT_TERM_FOR_FIRST_RANGE);
    }

    return findSliderTermForRange(TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE, realTerm, DEFAULT_TERM_FOR_SECOND_RANGE);
  }

  if (AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD <= amount && amount < AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD) {
    return findSliderTermForRange(TERM_SLIDER_REAL_VALUES_FOR_THIRD_RANGE, realTerm, DEFAULT_TERM_FOR_THIRD_RANGE);
  }

  return findSliderTermForRange(TERM_SLIDER_REAL_VALUES_FOR_FOURTH_RANGE, realTerm, DEFAULT_TERM_FOR_FOURTH_RANGE);
};

function findSliderTermForRange(range: RealTermBySliderValue, realTerm: number, defaultValue: number) {
  const termSliderValue = Object.entries(range).find(
    ([, value]: [string, TermSliderRealData]) => Number(value.value) === Number(realTerm),
  );

  if (termSliderValue) {
    return {
      shouldUpdateRealTerm: false,
      termSliderValue: Number(termSliderValue[0]),
    };
  }

  return {
    shouldUpdateRealTerm: true,
    termSliderValue: defaultValue,
  };
}
