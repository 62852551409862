import { useCallback } from 'react';

import { NumberInput, Slider } from '@sravni/react-design-system';
import type { SliderInputProps } from '@sravni/react-design-system/dist/types/components/SliderInput';

import { FILTERS_NAMES } from '@src/constants/filters';
import { sendTermFilterEvent } from '@src/helpers/analyticsEvents';
import { debounce } from '@src/utils/debounce';
import { useLatest } from 'shared/hooks';

import styles from './FullDealTermSlider.module.scss';

const debouncedAnalyticEvent = debounce(sendTermFilterEvent, 600);

interface Props extends Omit<SliderInputProps, 'onChange' | 'value'> {
  value?: number | null;
  visibleValue: number;
  onChange: (value: number | undefined, name: string) => void;
  defaultValue?: number;
  offerId?: string;
}

export const FullDealTermSlider: React.FC<Props> = ({ value, onChange, defaultValue, visibleValue, ...props }) => {
  const currentValue = useLatest(value || defaultValue);

  const handleChangeAmountInput = (newValue = 0) => {
    if (newValue === currentValue.current) {
      return;
    }

    debouncedAnalyticEvent();
    onChange(newValue, FILTERS_NAMES.TERM);
  };

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.currentTarget.value === '') {
        onChange(defaultValue, FILTERS_NAMES.TERM);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue],
  );

  return (
    <div data-qa="SliderInput" className={styles.container}>
      <NumberInput value={visibleValue} readOnly label="Срок" postfix={props.postfix} />
      <Slider
        onBlur={handleBlur}
        value={value}
        defaultValue={defaultValue}
        inputMode="numeric"
        onChange={handleChangeAmountInput}
        step={props.step}
        className={styles.slider}
        {...props}
      />
    </div>
  );
};
