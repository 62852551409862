import React from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { useCardClick } from '@src/components/ProductList/components/Card/useCardClick';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { Experiments } from '@src/constants/abTest';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { TO_FULL_DEAL_BOTTOM_SHEET_BUTTON_DEFAULT_TEXT } from 'entities/FullDeal/config';
import { SRAVNI_LOGO_URL } from 'shared/config/logoUrl';

import { buildHasOfferLinkForFullDeal } from '../../lib';

import styles from './FullDealCalculatorBottomSheetActions.module.scss';

interface Props {
  amount: number;
  term: number;
  hasOffer: boolean;
  toFullDealButtonText: string;

  offer: IClientMicrocreditListItem;
  position: number;
}

export const FullDealCalculatorBottomSheetActions = ({
  amount,
  term,
  hasOffer,
  toFullDealButtonText,

  offer,
  position,
}: Props) => {
  const { organization, name, _id } = offer;
  const { sourceLink } = useCardClick({
    offer,
    position,
  });

  // @todo: Хардкод для офера ПС, Егор Ильясов сказал сделать именно так)
  // Получи заём|Заём|6734b6ec375990002a1a1dab|6734b8eba871da0027300346
  const affSub4: AffSub4 = {
    organizationName: 'Получи заём',
    productName: 'Заём',
    organizationId: '6734b6ec375990002a1a1dab',
    productId: '6734b8eba871da0027300346',
  };

  const affSub4ForMfoButton: AffSub4 = {
    organizationName: organization.name,
    productName: name,
    organizationId: organization._id,
    productId: _id,
  };

  const sourceAffC = `${sourceLink}|position${position}`;
  const _toFullDealButtonText = toFullDealButtonText || TO_FULL_DEAL_BOTTOM_SHEET_BUTTON_DEFAULT_TEXT;

  return (
    <>
      <AdvertisingButton
        advSub={offer.advertising.advSub}
        analytics={offer}
        affSub4={affSub4}
        source={sourceAffC}
        className={styles.advertisingButton}
        size={52}
        // @fix проверить параметр показа showAuth
        link={buildHasOfferLinkForFullDeal(offer.advertising.offerId, amount, term)}
        visibleLabel={
          <>
            {!hasOffer && <OptimizedPicture img={SRAVNI_LOGO_URL} alt="logo" width={15} height={13} />}
            {_toFullDealButtonText}
          </>
        }
      />

      <AdvertisingButton
        advSub={offer.advertising.advSub}
        analytics={offer}
        affSub4={affSub4ForMfoButton}
        source={sourceAffC}
        className={styles.advertisingButton}
        size={52}
        variant="secondary"
        visibleLabel={`Продолжить на сайте ${offer.organization.name}`}
      />
    </>
  );
};
