import { sendOpenAppFullDealEvent } from '@src/helpers/analyticsEvents';

import { FULL_DEAL_ONE_LINK } from '../config';

export const openMobileApp = (event: React.SyntheticEvent, seoHeading: string) => {
  event.stopPropagation();

  sendOpenAppFullDealEvent(seoHeading);
  window.open(FULL_DEAL_ONE_LINK, '_blank');
};
