import React, { memo } from 'react';

import { Badge, Space, Typography } from '@sravni/react-design-system';
import { useTheme } from '@sravni/react-utils';

import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';

import darkThemeImage from './assets/darkThemeImage.png';
import lightThemeImage from './assets/lightThemeImage.png';
import styles from './FullDealEntryPointBanner.module.scss';
import { useBadgeTime } from './lib';

interface Props {
  hasOffer: boolean;
}

export const FullDealEntryPointBanner = memo(({ hasOffer }: Props) => {
  const [theme] = useTheme();
  const time = useBadgeTime(hasOffer);

  return (
    <Space justify="space-between" className={styles.container}>
      <div>
        <Typography.Heading level={1} className={styles.title}>
          Первый займ без процентов
        </Typography.Heading>

        <Badge variant="primary" color="gray" text={hasOffer ? 'ЕСТЬ АКТИВНАЯ ЗАЯВКА' : `ПОЛУЧИТЕ ДЕНЬГИ В ${time}`} />
      </div>

      <OptimizedPicture
        imgClassName={styles.image}
        img={theme === 'lager' ? lightThemeImage : darkThemeImage}
        width={177}
        height={120}
        loading="eager"
        fetchpriority="high"
        alt="logo"
      />
    </Space>
  );
});

FullDealEntryPointBanner.displayName = 'FullDealEntryPointBanner';
