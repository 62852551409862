import React, { memo } from 'react';

import { Card, Typography } from '@sravni/react-design-system';
import { Space } from '@sravni/react-design-system/lib/Space';

import { formatMoney } from '@src/helpers/formatMoney';

import { formatDateWithOffset } from '../../lib';

import styles from './FullDealOffer.module.scss';

type Props = {
  amount: number;
  term: number;
};

export const FullDealOffer = memo(({ amount, term }: Props) => (
  <Card className={styles.container} color="dark" size={16}>
    <Space justify="space-between">
      <Typography.Text size={14}>Сумма</Typography.Text>

      <Typography.Text size={14} strong>
        {formatMoney(amount)}
      </Typography.Text>
    </Space>

    <Space justify="space-between">
      <Typography.Text size={14}>Погасить до</Typography.Text>
      <Typography.Text size={14} strong>
        {formatDateWithOffset(term - 1)}
      </Typography.Text>
    </Space>
  </Card>
));

FullDealOffer.displayName = 'FullDealOffer';
