import type { ReactNode } from 'react';

import { Alert, Icon, Space, Typography } from '@sravni/react-design-system';
import { useBoolean } from '@sravni/react-utils';

import { Info } from '@src/components/icons/info';
import { CurrencySymbol } from 'shared/config/internalization';

import { PromoGuideSheet } from '../PromoGuideSheet';

import styles from './PromoInfoBox.module.scss';

export const PromoInfoBox = ({ children }: { children: ReactNode }) => {
  const [visible, toogle] = useBoolean();

  return (
    <Alert
      className={styles.alert}
      variant="secondary"
      title={
        <Space direction="horizontal" justify="space-between">
          <Typography.Text size={14}>Бонус от Сравни</Typography.Text>
          <Space direction="horizontal" className={styles.container}>
            <Typography.Text size={14} className={styles.symbol}>
              +500
            </Typography.Text>
            <Typography.Text strong size={14} className={styles.symbol}>
              {CurrencySymbol.RUB}
            </Typography.Text>
            <Icon size={16} className={styles.icon} onClick={toogle.on}>
              <Info />
            </Icon>
          </Space>
        </Space>
      }
    >
      {children}
      <PromoGuideSheet visible={visible} onClose={toogle.off} onButtonClick={toogle.off} />
    </Alert>
  );
};
