const DAYS_FOR_CALCULATE_RATE = 14;
const DAYS_IN_YEAR = 365;

// @link https://sravni-corp.atlassian.net/wiki/spaces/MOB/pages/1076756624#%D0%AD%D0%BB%D0%B5%D0%BC%D0%B5%D0%BD%D1%82-%E2%80%9D%D0%A0%D0%B0%D1%81%D1%87%D0%B5%D1%82%E2%80%9D
export const calculateLongDebt = (amount: number, term: number, rate: number): number => {
  const ratePerTerm = (rate / DAYS_IN_YEAR) * DAYS_FOR_CALCULATE_RATE;

  const numerator = ratePerTerm * amount;
  const denominator = 1 - (1 + ratePerTerm) ** (-term / 2);

  return Math.round(numerator / denominator);
};
