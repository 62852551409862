import type { SyntheticEvent } from 'react';
import React from 'react';

import { Button } from '@sravni/react-design-system';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { useCardClick } from '@src/components/ProductList/components/Card/useCardClick';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { PromoInfoBox } from 'entities/Promo/ui/PromoInfoBox/PromoInfoBox';
import { FULL_DEAL_MOBILE_APP_LINKS } from 'features/FullDeal/config';
import { buildHasOfferLinkForFullDeal } from 'features/FullDeal/lib';
import styles from 'features/FullDeal/ui/FullDealCalculatorBottomSheetActions/FullDealCalculatorBottomSheetActions.module.scss';
import { SRAVNI_LOGO_URL } from 'shared/config/logoUrl';

import { PromoDealOffersActionsTexts } from '../PromoDealOfferActions/PromoDealOffersActions.texts';

interface Props {
  amount: number;
  term: number;
  hasOffer: boolean;
  offer: IClientMicrocreditListItem;
  position: number;
}

export const PromoDealCalculatorBottomSheetActions = ({ amount, term, hasOffer, offer, position }: Props) => {
  const { sourceLink } = useCardClick({
    offer,
    position,
  });

  // @todo: Хардкод для офера ПС, Егор Ильясов сказал сделать именно так)
  // Получи заём|Заём|6734b6ec375990002a1a1dab|6734b8eba871da0027300346
  const affSub4: AffSub4 = {
    organizationName: 'Получи заём',
    productName: 'Заём',
    organizationId: '6734b6ec375990002a1a1dab',
    productId: '6734b8eba871da0027300346',
  };

  const sourceAffC = `${sourceLink}|position${position}`;

  /**
   * новая ссылка на редирект в приложение
   * @param e f
   */
  const handleRedirectApp = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open(FULL_DEAL_MOBILE_APP_LINKS.FROM_MODAL_OFFER, '_blank');
  };
  return (
    <>
      <PromoInfoBox>
        <Button size={52} variant="primary" onClick={handleRedirectApp}>
          <OptimizedPicture img={SRAVNI_LOGO_URL} alt="logo" width={15} height={13} />
          {PromoDealOffersActionsTexts.getInApp}
        </Button>
      </PromoInfoBox>

      <AdvertisingButton
        variant="secondary"
        advSub={offer.advertising.advSub}
        analytics={offer}
        affSub4={affSub4}
        source={sourceAffC}
        className={styles.advertisingButton}
        size={52}
        link={buildHasOfferLinkForFullDeal(offer.advertising.offerId, amount, term, true)}
        visibleLabel={PromoDealOffersActionsTexts.continueWebsite}
      />
    </>
  );
};
