import { isProduction } from '@src/utils';

import { FULL_DEAL_ENTRY_POINT_TEXTS } from '../config';

import { sendOpenWebFullDealEventV2 } from './analytics';

const PERSONAL_PROFILE_PROD_LINK = 'https://sravni.id/user/profile/microcredits/';
const PERSONAL_PROFILE_DEV_LINK = 'https://master.sravni.id.dev.lan/user/profile/microcredits/';

export const openPersonalProfile = (event: React.SyntheticEvent) => {
  event.stopPropagation();

  sendOpenWebFullDealEventV2(FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText);
  window.open(isProduction ? PERSONAL_PROFILE_PROD_LINK : PERSONAL_PROFILE_DEV_LINK, '_blank');
};
