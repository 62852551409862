import type { FC } from 'react';

import { Alert, Button, Sheet, Space, Typography } from '@sravni/react-design-system';

import { PROMO_GUIDE_SHEET_LINK } from './PromoGuideSheet.config';
import styles from './PromoGuideSheet.module.scss';
import { PromoGuideSheetTexts } from './PromoGuideSheet.texts';

type PromoGuideSheetProps = { visible: boolean; onClose: () => void; onButtonClick: () => void };

export const PromoGuideSheet: FC<PromoGuideSheetProps> = ({ visible, onClose, onButtonClick }) => (
  <Sheet visible={visible} onClose={onClose}>
    <Sheet.Header>
      <Typography.Text size={20} strong>
        {PromoGuideSheetTexts.title}
      </Typography.Text>
    </Sheet.Header>
    <Sheet.Content>
      <Space direction="vertical" size={8}>
        <Typography.Text className={styles.content}>{PromoGuideSheetTexts.content}</Typography.Text>
        <Alert variant="secondary" className={styles.description} color="dark">
          {PromoGuideSheetTexts.description}
        </Alert>
      </Space>
      <Typography.Link href={PROMO_GUIDE_SHEET_LINK}>{PromoGuideSheetTexts.linkText}</Typography.Link>
    </Sheet.Content>
    <Sheet.Footer>
      <Button onClick={onButtonClick} variant="primary" size={52}>
        {PromoGuideSheetTexts.buttonText}
      </Button>
    </Sheet.Footer>
  </Sheet>
);
