export const Info = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 17.25C5.99555 17.25 2.75 14.0045 2.75 10C2.75 5.99555 5.99555 2.75 10 2.75C14.0045 2.75 17.25 5.99555 17.25 10C17.25 14.0045 14.0045 17.25 10 17.25Z"
      stroke="#636F7D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 8.91699V13.0837V8.91699Z" fill="#171D23" />
    <path d="M10 8.91699V13.0837" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.99701 6C9.58301 6 9.24702 6.336 9.25002 6.75C9.25002 7.164 9.58602 7.5 10 7.5C10.414 7.5 10.75 7.164 10.75 6.75C10.75 6.336 10.414 6 9.99701 6Z"
      fill="#636F7D"
    />
  </svg>
);
