import { getDaysPluralize, getWeeksPluralize } from '@src/helpers/strings';

import type { TermSliderRealData } from '../types';

export const getTermPrefix = (termSliderRealData: TermSliderRealData | undefined) => {
  if (!termSliderRealData) {
    return '';
  }

  return termSliderRealData.isDays
    ? getDaysPluralize(termSliderRealData.visibleValue)
    : getWeeksPluralize(termSliderRealData.visibleValue);
};
