import { makeMainGEvent, pushEvent } from '@src/utils/analytics';
import { FULL_DEAL_EVENT_CATEGORY } from 'entities/FullDeal/config';

export const sendOpenWebFullDealEventV2 = (buttonLabel: string, isLanding = false) => {
  const eventLabel = isLanding
    ? `Калькулятор 2.0, ${buttonLabel}|screen - mfoFullDealLanding`
    : `Реферальня витрина, Баннер, Калькулятор 2.0, ${buttonLabel}|screen - mfoReferral`;

  pushEvent(
    makeMainGEvent({
      eventCategory: FULL_DEAL_EVENT_CATEGORY,
      eventAction: 'mfoNextButton',
      eventLabel,
      eventValue: undefined,
    }),
  );
};
