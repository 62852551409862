import { useRouter } from 'next/router';
import type { RefObject, SyntheticEvent } from 'react';
import React from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import type { AbTestingExperiments } from '@src/constants/abTest';
import { fullDealLandingPageRoute } from '@src/shared/constants/pageRoutes';
import { PromoInfoBox } from 'entities/Promo/ui/PromoInfoBox/PromoInfoBox';
import { FULL_DEAL_MOBILE_APP_LINKS } from 'features/FullDeal/config';
import { openWebFullDeal } from 'features/FullDeal/lib';
import { SRAVNI_LOGO_URL } from 'shared/config/logoUrl';

import { PromoDealOffersActionsTexts } from './PromoDealOffersActions.texts';

interface PromoDealOffersActionsProps {
  amount: number;
  term: number;
  hasOffer: boolean;

  toFullDealButtonText: string;
  toFullDealButtonColor?: ButtonProps['color'];

  seoHeading?: string;
  toFullDealButtonRef?: RefObject<HTMLButtonElement>;
  abTestingExperiments: AbTestingExperiments;
  webFullDealAnalytics?: (buttonLabel: string) => void;
}

export const PromoDealOffersActions = ({
  amount,
  term,
  hasOffer,
  toFullDealButtonText,
  toFullDealButtonColor = 'gray',
  seoHeading,
  toFullDealButtonRef,
  abTestingExperiments,

  webFullDealAnalytics,
}: PromoDealOffersActionsProps) => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');
  /**
   * новая ссылка на редирект в приложение
   * @param e f
   */
  const handleRedirectApp = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open(
      pathWithoutQuery.includes(fullDealLandingPageRoute)
        ? FULL_DEAL_MOBILE_APP_LINKS.FROM_LANDING
        : FULL_DEAL_MOBILE_APP_LINKS.FROM_REFERAL_VITRINA,
      '_blank',
    );
  };
  return (
    <>
      <PromoInfoBox>
        <Button size={52} variant="primary" color={toFullDealButtonColor} onClick={handleRedirectApp}>
          <OptimizedPicture img={SRAVNI_LOGO_URL} alt="logo" width={15} height={13} />
          {PromoDealOffersActionsTexts.getInApp}
        </Button>
      </PromoInfoBox>

      <Button
        variant="secondary"
        color={toFullDealButtonColor}
        size={52}
        onClick={(event) =>
          openWebFullDeal(event, {
            buttonLabel: toFullDealButtonText,
            amount,
            term,
            hasOffer,
            analytics: webFullDealAnalytics,
            abTestingExperiments,
          })
        }
        ref={toFullDealButtonRef}
      >
        {PromoDealOffersActionsTexts.continueWebsite}
      </Button>
    </>
  );
};
