import cn from 'classnames';
import React, { memo } from 'react';

import { Badge, Card, Space, Typography } from '@sravni/react-design-system';
import pluralize from '@sravni/utils/lib/pluralize';

import { formatMoney } from '@src/helpers/formatMoney';
import { getWeeksPluralize } from '@src/helpers/strings';

import { DAYS_IN_WEEK, TERM_SLIDER_MAX_THIRD_RANGE, TERM_SLIDER_MIN_THIRD_RANGE } from '../../config';
import { formatDateWithOffset } from '../../lib';

import {
  LONG_TERM_FIRST_RANGE_COEFFICIENT,
  LONG_TERM_SECOND_RANGE_COEFFICIENT,
} from './FullDealEntryPointFormCalculations.config';
import styles from './FullDealEntryPointFormCalculations.module.scss';
import { calculateLongDebt, calculateShortDebt } from './lib';

interface Props {
  term: number;
  amount: number;
}

export const FullDealEntryPointFormCalculations = memo(({ term, amount }: Props) => {
  const withoutOverpayment = term < 22;
  const isShowShortDebt = term < 34;

  const amountWithOverpayment = calculateShortDebt(amount, term);

  if (isShowShortDebt) {
    return (
      <Card className={styles.container} color="dark" size={16}>
        <Space className={cn(styles.content, 'h-mb-16')}>
          <Typography.Text size={14} className={styles.halfOfBlock}>
            К возврату
          </Typography.Text>

          <Space size={4} justify="end" align="center">
            {withoutOverpayment && (
              <>
                <Badge
                  className={styles.badge}
                  color="green"
                  variant="primary"
                  text={<Typography.Text size={14}>0%</Typography.Text>}
                />
                <Typography.Text size={14} className={styles.decoratedText}>
                  {formatMoney(amountWithOverpayment, { withSymbol: false })}
                </Typography.Text>
              </>
            )}

            <Typography.Text size={14} className="h-color-G100" strong>
              {withoutOverpayment ? formatMoney(amount) : formatMoney(amountWithOverpayment)}
            </Typography.Text>
          </Space>
        </Space>

        <Space className={styles.content}>
          <Typography.Text size={14} className={styles.halfOfBlock}>
            Погасить до
          </Typography.Text>
          <Typography.Text size={14} strong className={styles.alignRight}>
            {formatDateWithOffset(term - 1)}
          </Typography.Text>
        </Space>
      </Card>
    );
  }

  const termByWeeks = Math.round(term / DAYS_IN_WEEK);
  const calculations =
    termByWeeks > TERM_SLIDER_MIN_THIRD_RANGE && termByWeeks < TERM_SLIDER_MAX_THIRD_RANGE
      ? calculateLongDebt(amount, termByWeeks, LONG_TERM_FIRST_RANGE_COEFFICIENT)
      : calculateLongDebt(amount, termByWeeks, LONG_TERM_SECOND_RANGE_COEFFICIENT);

  return (
    <Card className={styles.longDebtContainer} color="dark">
      <Space className={cn(styles.content, 'h-mb-16')}>
        <Typography.Text size={14} className={styles.halfOfBlock}>
          Срок займа
        </Typography.Text>

        <Typography.Text size={14} strong className={styles.alignRight}>
          на {termByWeeks} {getWeeksPluralize(termByWeeks)}
        </Typography.Text>
      </Space>

      <Space className={cn(styles.content, 'h-mb-8')}>
        <Typography.Text size={14} className={styles.halfOfBlock}>
          Платеж раз в 2 недели
        </Typography.Text>
        <Typography.Text size={14} strong className={styles.alignRight}>
          {formatMoney(calculations)}
        </Typography.Text>
      </Space>

      <Space className={styles.subtitles}>
        <Typography.Text size={10} className="h-color-D40">
          начиная с {formatDateWithOffset(14, false)}
        </Typography.Text>
        <Typography.Text size={10} className={cn(styles.alignRight, 'h-color-D40')}>
          {termByWeeks / 2} {pluralize(termByWeeks / 2, 'платеж', 'платежа', 'платежей')}
        </Typography.Text>
      </Space>
    </Card>
  );
});

FullDealEntryPointFormCalculations.displayName = 'FullDealEntryPointFormCalculations';
