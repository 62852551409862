import React, { memo, useCallback, useMemo } from 'react';

import { Space, Typography } from '@sravni/react-design-system';

import { AmountControlV2 } from '@src/components/ui/AmountControlV2';
import { FILTERS_NAMES } from '@src/constants/filters';
import { sendAmountFilterEvent } from '@src/helpers/analyticsEvents';
import { formatMoney } from '@src/helpers/formatMoney';
import { FullDealTermSlider } from 'entities/FullDeal';
import { CurrencySymbol } from 'shared/config/internalization';
import { useLatest } from 'shared/hooks';

import { AMOUNT_MAX, AMOUNT_SLIDER_STEP, AMOUNT_MIN } from '../../config';

import styles from './FullDealEntryPointForm.module.scss';
import { getTermPrefix, useTermSlider } from './lib';

interface Props {
  amount: number;
  term: number;
  onChange: (name: FILTERS_NAMES, value: string | number) => void;
}

export const FullDealEntryPointForm = memo(({ amount, term, onChange }: Props) => {
  const { minTerm, maxTerm, minLabel, maxLabel, termSliderValue, termSliderRealData, setTerm } = useTermSlider({
    amount,
    term,
    onChange,
  });

  const onChangeAmount = useCallback(
    (value: number | undefined) => {
      onChange(FILTERS_NAMES.AMOUNT, value || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const termPostfix = useMemo(() => getTermPrefix(termSliderRealData), [termSliderRealData]);

  return (
    <Space direction="vertical" size={16}>
      <div className={styles.field}>
        <AmountControlV2
          analyticsEvent={sendAmountFilterEvent}
          steps={[{ ceilThreshold: AMOUNT_MAX, step: AMOUNT_SLIDER_STEP }]}
          name={FILTERS_NAMES.AMOUNT}
          label="Сумма"
          value={amount}
          min={AMOUNT_MIN}
          max={AMOUNT_MAX}
          onChange={onChangeAmount}
          postfix={CurrencySymbol.RUB}
          className={styles.input}
        />

        <Space className={styles.inputLabels} justify="space-between">
          <Typography.Text size={12} className="h-color-D40">
            от {formatMoney(AMOUNT_MIN)}
          </Typography.Text>
          <Typography.Text size={12} className="h-color-D40">
            до {formatMoney(AMOUNT_MAX)}
          </Typography.Text>
        </Space>
      </div>

      <div className={styles.field}>
        <FullDealTermSlider
          visibleValue={termSliderRealData?.visibleValue}
          value={termSliderValue}
          onChange={setTerm}
          postfix={termPostfix}
          min={minTerm}
          max={maxTerm}
          step={1}
        />

        <Space className={styles.inputLabels} justify="space-between">
          <Typography.Text size={12} className="h-color-D40">
            {minLabel}
          </Typography.Text>
          <Typography.Text size={12} className="h-color-D40">
            {maxLabel}
          </Typography.Text>
        </Space>
      </div>
    </Space>
  );
});

FullDealEntryPointForm.displayName = 'FullDealEntryPointForm';
