import React, { memo } from 'react';

import { Button, Icon, Space } from '@sravni/react-design-system';
import { Plus } from '@sravni/react-icons';

import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import type { AbTestingExperiments } from '@src/constants/abTest';
import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { sendShowProductEvent } from '@src/helpers/analyticsEvents';
import { scrollToElementById } from '@src/helpers/common';
import { SRAVNI_LOGO_URL } from 'shared/config/logoUrl';

import { FULL_DEAL_ENTRY_POINT_TEXTS } from '../../config';
import { openMobileApp, openWebFullDeal } from '../../lib';

import styles from './FullDealEntryPointActions.module.scss';

interface Props {
  amount: number;
  term: number;
  hasOfferOrForm: boolean;
  hasOffer: boolean;
  hasIssuedMicrocreditDeals: boolean;
  seoHeading: string;
  abTestingExperiments: AbTestingExperiments;
}

export const FullDealEntryPointActions = memo(
  ({ amount, term, hasOfferOrForm, hasOffer, hasIssuedMicrocreditDeals, seoHeading, abTestingExperiments }: Props) => {
    const isViewOffersButtonVisible = hasOfferOrForm || !hasIssuedMicrocreditDeals;

    const scrollToOffers = () => {
      sendShowProductEvent();
      scrollToElementById(PRODUCT_LIST_ANCHOR);
    };

    return (
      <Space size={8} direction="vertical">
        {!hasOfferOrForm && (
          <>
            {hasIssuedMicrocreditDeals && (
              <>
                <Button
                  size={52}
                  variant="primary"
                  color="gray"
                  onClick={(event) =>
                    openWebFullDeal(event, {
                      buttonLabel: `${FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText} (${seoHeading})`,
                      amount,
                      term,
                      hasOffer,
                      abTestingExperiments,
                    })
                  }
                >
                  <Icon size={20} icon={<Plus />} />
                  {FULL_DEAL_ENTRY_POINT_TEXTS.newDealButtonText}
                </Button>

                <Button size={52} color="gray" onClick={(e) => openMobileApp(e, seoHeading)}>
                  {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
                </Button>
              </>
            )}

            {!hasIssuedMicrocreditDeals && (
              <Button
                size={52}
                variant="primary"
                color="gray"
                onClick={(event) =>
                  openWebFullDeal(event, {
                    buttonLabel: FULL_DEAL_ENTRY_POINT_TEXTS.pickUpLoan,
                    amount,
                    term,
                    hasOffer,
                    abTestingExperiments,
                  })
                }
              >
                {FULL_DEAL_ENTRY_POINT_TEXTS.pickUpLoan}
                <OptimizedPicture imgClassName={styles.logo} img={SRAVNI_LOGO_URL} alt="logo" width={23} height={20} />
              </Button>
            )}
          </>
        )}

        {isViewOffersButtonVisible && (
          <Button
            variant="secondary"
            color="gray"
            size={52}
            onClick={scrollToOffers}
            className={styles.buttonFullWidth}
          >
            Посмотреть предложения
          </Button>
        )}
      </Space>
    );
  },
);

FullDealEntryPointActions.displayName = 'FullDealEntryPointActions';
