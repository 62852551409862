import { useCallback, useEffect, useMemo, useRef } from 'react';

import { SliderInput } from '@sravni/react-design-system';

import { RUSSIA_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { sendChangeAmountFilterEvent } from '@src/helpers/analyticsEvents';
import { validateByParams } from '@src/helpers/validate';
import { debounce } from '@src/utils/debounce';

type Steps = Array<{ ceilThreshold: number; step: number }>;

interface IProps {
  className?: string;
  value?: number | null;
  name: string;
  label?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  error?: string;
  onChange: (value: number | undefined, name: string) => void;
  format?: string | ((value: string) => string);
  postfix?: string;
  defaultValue?: number;
  icon?: React.ReactNode;
  autoFocus?: boolean;
  steps: Steps;
  offerId?: string;
  analyticsEvent?: VoidFunction;
}

const THOUSAND_SEPARATOR = ' ';

export const AmountControlV2: React.FC<IProps> = ({
  value,
  name,
  label,
  min = 0,
  max = 100000,
  onChange,
  defaultValue,
  error,
  steps,
  offerId,
  analyticsEvent,
  ...props
}) => {
  const validate = useMemo(() => validateByParams({ max }), [max]);

  const step = useMemo(() => getStep(value || 0, steps), [value, steps]);

  const validatedValue = validate(value, step);

  const handleChange = useRef(onChange);

  useEffect(() => {
    // валидация: если в query-параметрах кто-то вручную исправит значение
    if (validatedValue !== value) {
      handleChange.current(validatedValue, name);
    }
    if (validatedValue < min) handleChange.current(min, name);
    if (validatedValue > max) handleChange.current(max, name);
  }, [max, min, name, validatedValue, value]);

  const handleChangeAmountInput = useCallback(
    (value = 0) => {
      if (value < steps[0]?.step) return;
      const updatedStep = getStep(value, steps);
      const valueAfterValidating = validate(value, updatedStep);
      const from = offerId ? 'Карточка детальной информации' : 'Витрина';
      debounce(
        () =>
          analyticsEvent ? analyticsEvent() : sendChangeAmountFilterEvent(valueAfterValidating || 0, from, offerId),
        600,
      )();

      handleChange.current(valueAfterValidating, name);
    },
    [steps, validate, offerId, name, analyticsEvent],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.currentTarget.value === '') {
        handleChange.current(defaultValue, name);
      }
    },
    [defaultValue, name],
  );

  return (
    <SliderInput
      onBlur={handleBlur}
      label={error || label}
      value={validatedValue}
      min={min}
      max={max}
      defaultValue={defaultValue}
      invalid={Boolean(error)}
      thousandSeparator={THOUSAND_SEPARATOR}
      inputMode="numeric"
      onChange={handleChangeAmountInput}
      step={step}
      maxLength={RUSSIA_AMOUNT_FIELD_SETTINGS.maxLength}
      {...props}
    />
  );
};

function getStep(amount: number, steps: Steps, fallbackStep = 1000) {
  const appropriateObj = steps.find(({ ceilThreshold }) => amount < ceilThreshold) ?? steps[steps.length - 1];

  return appropriateObj?.step ?? fallbackStep;
}
