import {
  AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD,
  AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD,
  TERM_SLIDER_MAX_FOR_FIRST_RANGE,
  TERM_SLIDER_MAX_THIRD_RANGE,
  DAYS_IN_WEEK,
} from 'entities/FullDeal/config';
import { DAYS_IN_MONTH, TERM_MAX } from 'features/FullDeal/config';

const daysToWeeksForThirdRange = {
  60: 10,
  90: 14,
  120: 18,
  150: 22,
};

export const convertFullDealDataToEntryPointData = (amount: number, term: number) => {
  if (amount < AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD) {
    return {
      amount,
      term: Math.min(term, TERM_SLIDER_MAX_FOR_FIRST_RANGE),
    };
  }

  if (AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD <= amount && amount < AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD) {
    return {
      amount,
      term: (daysToWeeksForThirdRange[term] ?? TERM_SLIDER_MAX_THIRD_RANGE) * DAYS_IN_WEEK,
    };
  }

  let _term = 46;

  if (term === DAYS_IN_MONTH * 11) {
    _term = 48;
  }

  if (term === TERM_MAX) {
    _term = 52;
  }

  return {
    amount,
    term: _term * DAYS_IN_WEEK,
  };
};
