import {
  AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD,
  AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD,
  AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD,
  TERM_SLIDER_MAX_FOR_FIRST_RANGE,
  TERM_SLIDER_MAX_FOURTH_RANGE,
  TERM_SLIDER_MAX_THIRD_RANGE,
  TERM_SLIDER_MIN_FOURTH_RANGE,
  TERM_SLIDER_MIN_SECOND_RANGE,
  TERM_SLIDER_MIN_THIRD_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_FOURTH_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE,
  TERM_SLIDER_REAL_VALUES_FOR_THIRD_RANGE,
} from 'entities/FullDeal/config';

import type { TermSliderData } from '../../types';

export const getTermSliderData = (amount: number): TermSliderData => {
  if (amount < AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD) {
    return {
      min: TERM_SLIDER_MIN_SECOND_RANGE,
      max: TERM_SLIDER_MAX_FOR_FIRST_RANGE,
      minLabel: `от ${TERM_SLIDER_MIN_SECOND_RANGE} дней`,
      maxLabel: `до ${TERM_SLIDER_MAX_FOR_FIRST_RANGE} дней`,
      realTermBySliderValue: TERM_SLIDER_REAL_VALUES_FOR_FIRST_RANGE,
    };
  }

  if (AMOUNT_SLIDER_FIRST_RANGE_THRESHOLD <= amount && amount < AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD) {
    return {
      min: TERM_SLIDER_MIN_SECOND_RANGE,
      max: Object.keys(TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE).length + TERM_SLIDER_MIN_SECOND_RANGE - 1,
      minLabel: `от ${TERM_SLIDER_MIN_SECOND_RANGE} дней`,
      maxLabel: `до ${TERM_SLIDER_MAX_THIRD_RANGE} недель`,
      realTermBySliderValue: TERM_SLIDER_REAL_VALUES_FOR_SECOND_RANGE,
    };
  }

  if (AMOUNT_SLIDER_SECOND_RANGE_THRESHOLD <= amount && amount < AMOUNT_SLIDER_FOURTH_RANGE_THRESHOLD) {
    return {
      min: TERM_SLIDER_MIN_THIRD_RANGE,
      max: (TERM_SLIDER_MAX_THIRD_RANGE - TERM_SLIDER_MIN_THIRD_RANGE) / 2 + TERM_SLIDER_MIN_THIRD_RANGE,
      minLabel: `от ${TERM_SLIDER_MIN_THIRD_RANGE} недель`,
      maxLabel: `до ${TERM_SLIDER_MAX_THIRD_RANGE} недель`,
      realTermBySliderValue: TERM_SLIDER_REAL_VALUES_FOR_THIRD_RANGE,
    };
  }

  return {
    min: TERM_SLIDER_MIN_FOURTH_RANGE,
    max: (TERM_SLIDER_MAX_FOURTH_RANGE - TERM_SLIDER_MIN_FOURTH_RANGE) / 2 + TERM_SLIDER_MIN_FOURTH_RANGE,
    minLabel: `от ${TERM_SLIDER_MIN_FOURTH_RANGE} недель`,
    maxLabel: `до ${TERM_SLIDER_MAX_FOURTH_RANGE} недель`,
    realTermBySliderValue: TERM_SLIDER_REAL_VALUES_FOR_FOURTH_RANGE,
  };
};
