import { useCallback, useLayoutEffect } from 'react';

import { FILTERS_NAMES } from '@src/constants/filters';
import { useSelectFullDealCalculator, useUpdateFullDealCalculator } from 'entities/FullDeal/model';

import { useGetFullDealEntryPointDefaultValues } from './useGetFullDealEntryPointDefaultValues';

interface Props {
  initialAmount: number;
  initialTerm: number;

  shouldSetDefaultValuesInRedux: boolean;

  onChange?: (key: FILTERS_NAMES, value: number) => void;
}

export const useOnChangeCalculator = ({
  initialAmount,
  initialTerm,
  shouldSetDefaultValuesInRedux,
  onChange,
}: Props) => {
  const defaultFormValues = useGetFullDealEntryPointDefaultValues(initialAmount, initialTerm);

  const { amount, term } = useSelectFullDealCalculator();
  const updateCalculator = useUpdateFullDealCalculator();

  useLayoutEffect(() => {
    if (shouldSetDefaultValuesInRedux) {
      updateCalculator(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (key: FILTERS_NAMES, value: number) => {
      onChange?.(key, value);

      if (key === FILTERS_NAMES.AMOUNT) {
        updateCalculator({
          [FILTERS_NAMES.AMOUNT]: value,
        });
        return;
      }

      if (key === FILTERS_NAMES.TERM) {
        updateCalculator({
          [FILTERS_NAMES.TERM]: value,
        });
      }
    },
    [onChange, updateCalculator],
  );

  return {
    amount,
    term,
    handleChange,
  };
};
