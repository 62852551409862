import { useMemo } from 'react';

import { convertFullDealDataToEntryPointData } from 'features/FullDeal/ui/FullDealEntryPointForm/lib';

export const useGetFullDealEntryPointDefaultValues = (amount: number, term: number) =>
  useMemo(
    () => convertFullDealDataToEntryPointData(amount, term),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
