import add from 'date-fns/add';

import type { AbTestingExperiments } from '@src/constants/abTest';
import { Experiments } from '@src/constants/abTest';
import { sendOpenWebFullDealEvent } from '@src/helpers/analyticsEvents';
import { stringifyPath } from '@src/helpers/query';
import { Cookie, CookieKey } from '@src/modules/cookie';

import { FULL_DEAL_QUERY_PARAMS, WEB_FULL_DEAL_LINK } from '../config';

interface ArgsWithLoanSettings {
  buttonLabel: string;
  amount: number;
  term: number;
  hasOffer: boolean;
  abTestingExperiments: AbTestingExperiments;
  analytics?: (buttonLabel: string) => void;
}

interface SimpleArgs {
  buttonLabel: string;
  amount?: never;
  term?: never;
  hasOffer?: never;
  abTestingExperiments: AbTestingExperiments;
  analytics?: (buttonLabel: string) => void;
}

type Args = SimpleArgs | ArgsWithLoanSettings;

export const openWebFullDeal = (event: React.SyntheticEvent, args: Args) => {
  event.stopPropagation();

  const { buttonLabel, amount, term, hasOffer, analytics = sendOpenWebFullDealEvent } = args;

  if (!hasOffer) {
    const expires = add(new Date(), { days: 1 });

    if (amount) {
      Cookie.set(CookieKey.amount, amount.toString(), { expires, path: '/' });
    }

    if (term) {
      Cookie.set(CookieKey.term, term.toString(), { expires, path: '/' });
    }
  }

  analytics(buttonLabel);
  window.location.href = stringifyPath(WEB_FULL_DEAL_LINK, {
    [FULL_DEAL_QUERY_PARAMS.showAuth]: true,
    [FULL_DEAL_QUERY_PARAMS.referrer]: window.location.origin + window.location.pathname,
  });
};
