import { useEffect, useMemo } from 'react';

import { useIsMounted } from '@sravni/react-utils';

import { FILTERS_NAMES } from '@src/constants/filters';
import { useLatest } from 'shared/hooks';

import { convertRealTermToSliderTerm } from './convertRealTermToSliderTerm';
import { getTermSliderData } from './getTermSliderData';

interface Props {
  amount: number;
  term: number;

  onChange: (name: FILTERS_NAMES, value: string | number) => void;
}

export const useTermSlider = ({ amount, term, onChange }: Props) => {
  const isMounted = useIsMounted();

  const { termSliderValue, shouldUpdateRealTerm } = useMemo(
    () => convertRealTermToSliderTerm(amount, term),
    [amount, term],
  );
  const { min, max, realTermBySliderValue, ...other } = useMemo(() => getTermSliderData(amount), [amount]);

  // @todo: Скорее всего баг в Slider компоненте. Не обновляется значение внутри функции handleTermChange
  // пришлось обернуть в useLatest
  const actualRealTermBySliderValue = useLatest(realTermBySliderValue);

  const handleTermChange = (value: number) => {
    const realValue = actualRealTermBySliderValue.current?.[value]?.value;

    onChange(FILTERS_NAMES.TERM, realValue);
  };

  useEffect(() => {
    if (isMounted() && termSliderValue && shouldUpdateRealTerm) {
      handleTermChange(termSliderValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateRealTerm]);

  return {
    minTerm: min,
    maxTerm: max,
    termSliderValue,
    termSliderRealData: realTermBySliderValue[termSliderValue],
    setTerm: handleTermChange,
    ...other,
  };
};
