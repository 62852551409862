import qs from 'qs';

import { FULL_DEAL_QUERY_PARAMS } from '../config';

// @todo: в hasOffer название amount уже занято, поэтому задали такое название
const CUSTOM_AMOUNT_QUERY_NAME = 'requested_amount';
const FULL_DEAL_HAS_OFFER_LINK = 'https://sravni.go2cloud.org/aff_c?offer_id=2443&aff_id=2&url_id=13223';

export const buildHasOfferLinkForFullDeal = (
  offerId: string,
  amount: number,
  term: number,
  showAuth: boolean = false,
) =>
  `${FULL_DEAL_HAS_OFFER_LINK}&${qs.stringify({
    open_offer_id: offerId,
    [CUSTOM_AMOUNT_QUERY_NAME]: amount,
    [FULL_DEAL_QUERY_PARAMS.term]: term,
    // @todo: нам нужно либо поменять в ПС логику showAuth и смотреть только на значение true, либо передавать
    // здесь пустое значение для showAuth (hasOffers не может принимать опциональные кверики)
    [FULL_DEAL_QUERY_PARAMS.showAuth]: showAuth || '',
  })}`;
