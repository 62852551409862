import React, { memo } from 'react';

import { Sheet } from '@sravni/react-design-system';
import type { SheetProps } from '@sravni/react-design-system/dist/types/components/Sheet';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { getPromoVariant } from 'entities/Promo/lib/getPromoVariant';
import { FullDealCalculatorBottomSheetActions } from 'features/FullDeal';
import { PromoDealCalculatorBottomSheetActions } from 'features/Promo/ui/PromoDealCalculatorBottomSheetActions/PromoDealCalculatorBottomSheetActions';

import { FullDealEntryPoint } from '../FullDealEntryPoint/FullDealEntryPoint';

import styles from './FullDealCalculatorBottomSheet.module.scss';

interface Props extends Pick<SheetProps, 'visible' | 'onClose'> {
  heading?: string;

  path: string;
  offer: IClientMicrocreditListItem;
  position: number;
}

export const FullDealCalculatorBottomSheet = memo(({ visible, onClose, heading, offer, position, path }: Props) => (
  <Sheet visible={visible} onClose={onClose}>
    <Sheet.Content className={styles.sheetContent}>
      <FullDealEntryPoint
        shouldSetDefaultValuesInRedux={false}
        heading={heading}
        highlightOffer={false}
        renderActions={(props) =>
          getPromoVariant(
            path,
            <PromoDealCalculatorBottomSheetActions offer={offer} position={position} {...props} />,
            <FullDealCalculatorBottomSheetActions offer={offer} position={position} {...props} />,
          )
        }
      />
    </Sheet.Content>
  </Sheet>
));

FullDealCalculatorBottomSheet.displayName = 'FullDealCalculatorBottomSheet';
