import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import { Experiments } from '@src/constants/abTest';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useHasUtmLabel } from '@src/reducers/route';
import { isProverennyeZaymyPage } from '@src/utils/routing';

export const useIsShowFullDealEntrypoint = () => {
  const isMobile = useIsMobile();
  const hasUtmLabel = useHasUtmLabel();
  const { asPath } = useRouter();

  const isPageWithoutAbTest = isProverennyeZaymyPage(asPath);
  const { isBVariant: isBVariantForContext } = useTestVariant(Experiments.FULL_DEAL_CONTEXT_ENTRY_BLOCK);

  return useMemo(() => {
    if ((hasUtmLabel && isBVariantForContext) || isPageWithoutAbTest) {
      return isMobile;
    }

    return isMobile && !hasUtmLabel;
  }, [isMobile, hasUtmLabel, isBVariantForContext, isPageWithoutAbTest]);
};
