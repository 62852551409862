import React from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import type { AbTestingExperiments } from '@src/constants/abTest';

import { FULL_DEAL_ENTRY_POINT_TEXTS } from '../../config';
import { openMobileApp, openWebFullDeal } from '../../lib';

export interface FullDealOfferActionsProps {
  amount: number;
  term: number;
  hasOffer: boolean;
  hasIssuedMicrocreditDeals: boolean;
  toFullDealButtonText: string;
  toFullDealButtonColor?: ButtonProps['color'];

  seoHeading?: string;
  abTestingExperiments?: AbTestingExperiments;

  webFullDealAnalytics?: (buttonLabel: string) => void;
  onMyLoansClick?: (event: React.SyntheticEvent, seoHeading: string) => void;
}

export const FullDealOfferActions = ({
  amount,
  term,
  hasOffer,
  hasIssuedMicrocreditDeals,
  toFullDealButtonText,
  toFullDealButtonColor = 'gray',

  seoHeading,
  abTestingExperiments,

  webFullDealAnalytics,
  onMyLoansClick = openMobileApp,
}: FullDealOfferActionsProps) => (
  <>
    <Button
      variant="primary"
      color={toFullDealButtonColor}
      size={52}
      onClick={(event) =>
        openWebFullDeal(event, {
          buttonLabel: toFullDealButtonText,
          amount,
          term,
          hasOffer,
          analytics: webFullDealAnalytics,
          abTestingExperiments,
        })
      }
    >
      {toFullDealButtonText}
    </Button>

    {hasIssuedMicrocreditDeals && (
      <Button size={52} color="gray" onClick={(e) => onMyLoansClick(e, seoHeading)}>
        {FULL_DEAL_ENTRY_POINT_TEXTS.mobileAppButtonText}
      </Button>
    )}
  </>
);
