import { useMemo } from 'react';

import { useMicrocreditUserDealsState } from '@src/reducers/microcreditUserDeals';
import { AMOUNT_DEFAULT, TERM_DEFAULT } from 'features/FullDeal/config';

import { getDataAboutMicrocreditDeals } from './getDataAboutMicrocreditDeals';

interface Props {
  initialTerm?: number;
  initialAmount?: number;
  shortTermFormatter?: (shortTerm: string, amount: number) => string | number;
}

export function useMicrocreditDeals({
  initialAmount = AMOUNT_DEFAULT,
  initialTerm = TERM_DEFAULT,
  shortTermFormatter,
}: Props) {
  const microcreditDeals = useMicrocreditUserDealsState();

  return useMemo(
    () => getDataAboutMicrocreditDeals(microcreditDeals, initialAmount, initialTerm, shortTermFormatter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [microcreditDeals],
  );
}
