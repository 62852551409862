import { useLayoutEffect, useState } from 'react';

import { getBadgeTime } from './getBadgeTime';

export const useBadgeTime = (hasOffer: boolean) => {
  const [time, setTime] = useState(() => getBadgeTime());

  useLayoutEffect(() => {
    if (hasOffer) {
      return;
    }

    const id = setInterval(() => {
      setTime(getBadgeTime());
    }, 10_000);

    return () => clearInterval(id);
  }, [hasOffer]);

  return time;
};
